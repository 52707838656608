<template>
  <b-row>
    <b-col cols="6">
      <b-form-group
        label="Telefone"
        label-for="telefoneInput"
      >
        <b-input-group>
          <masked-input
            id="telefoneInput"
            ref="telefone"
            v-model="value.telefone"
            :mask="['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            class="form-control"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="6">
      <b-form-group
        label="Celular"
        label-for="celularInput"
      >
        <b-input-group>
          <masked-input
            id="celularInput"
            ref="celular"
            v-model="value.celular"
            :mask="['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            class="form-control"
            placeholder=""
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<style lang="scss">
.divContato {
  width: 14rem;
  display: inline-block;
  padding-right: 1rem;
  padding-left: 1rem;
}

.divContatoPrimeiro {
  width: 14rem;
  display: inline-block;
  padding-right: 1rem;
}
</style>

<script>
export default {
  nome: 'TelefoneCelular',
  props: {
    value: {
      type: Object,
      default: () => { },
    },
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.value = val
      }
    },
  },
}
</script>
